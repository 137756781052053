// MainVisual.tsx
import React from "react";
import styled from "styled-components";
import LogoComponent from "./LogoComponent";

const MainVisualWrapper = styled.div`
  background: #f2f2f2;
  padding: 20px 0;
  text-align: center;
`;

const MainVisualContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
`;

const MainVisualText = styled.div`
  text-align: center;
  font-size: 90%;

`;

const MainVisualHeading = styled.h1`
  font-size: 3em;
  color: #e8491d;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "Mochiy Pop P One", sans-serif;

  @media (max-width: 768px) {
    font-size: 2em;
    white-space: normal;
  }
`;

const MainVisualParagraph = styled.p`
  font-family: "Mochiy Pop P One", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5em;
  margin-top: 0px;
  @media (max-width: 768px) {
    white-space: normal;
    font-size: 1em;
  }
`;

const MainVisual: React.FC = () => (
  <MainVisualWrapper>
    <MainVisualContent>
      <LogoComponent />
      <MainVisualText>
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <style>
              @import
              url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');
            </style>
          </defs>
        </svg>
        <MainVisualHeading>プロの技術で、あなたの車を清潔に</MainVisualHeading>
        <MainVisualParagraph>
          車内清掃・シートクリーニングのエキスパートにお任せください！
        </MainVisualParagraph>
      </MainVisualText>
    </MainVisualContent>
  </MainVisualWrapper>
);

export default MainVisual;
