import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const CalculatorContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Result = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
`;
const Ctext = styled.div`
    color: red;
    font-size: 0.7em;

    @media (max-width: 500px) {
        font-size: 0.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

const Button = styled.button`
  background: #3399ff; // より鮮やかな水色
  &:hover {
    background: #0080ff;
  }
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 80%;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
`;

const Input = styled.input`
  width: 90%;
  padding: 8px;
  margin: 10px auto;  // 上下のマージンは10px、左右は自動
  display: block;  // ブロック要素として表示
  border-radius: 4px;
  border: 1px solid #ccc;
`;

type CarType = 'kei' | 'normal' | 'large';

const carTypes: { [key in CarType]: string } = {
  kei: '軽自動車',
  normal: '普通車',
  large: '大型車'
};

const CleaningCalculator: React.FC = () => {
    const [carType, setCarType] = useState<CarType>('kei');
    const [seats, setSeats] = useState(0);
    const [isFloorSoaked, setIsFloorSoaked] = useState(false);
    const [doors, setDoors] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
  
    const calculatePrice = useCallback(() => {
    let price = 0;

    // 基本料金
    switch (carType) {
      case 'kei':
        price += 13000;
        break;
      case 'normal':
        price += 15000;
        break;
      case 'large':
        price += 17000;
        break;
    }

    // 座席数による追加料金
    if (seats > 1) {
      price += (seats - 1) * 7000;
    }

    // 床浸透の場合の追加料金
    if (isFloorSoaked) {
      price += 10000;
    }

    // ドア数による追加料金
    price += doors * 5000;

    setTotalPrice(price);
  }, [carType, seats, isFloorSoaked, doors]);

  useEffect(() => {
    calculatePrice();
  }, [calculatePrice]);

  const handleInquiry = () => {
    setShowModal(true);
  };

  const sendInquiry = () => {
    const subject = encodeURIComponent('嘔吐清掃の問い合わせ');
    const body = encodeURIComponent(`
車種: ${carTypes[carType]}
座席数: ${seats}
床に浸透: ${isFloorSoaked ? 'はい' : 'いいえ'}
ドアの枚数: ${doors}
概算料金: ${totalPrice.toLocaleString()}円

電話番号: ${phone}
住所: ${address}

以上の内容で嘔吐清掃の見積もりを希望します。
    `);

    window.location.href = `mailto:spt.carcleeen@gmail.com?subject=${subject}&body=${body}`;
    setShowModal(false);
  };


  return (
    <CalculatorContainer>
      <FormGroup>
        <Label>車種</Label>
        <Select 
          value={carType} 
          onChange={(e) => setCarType(e.target.value as CarType)}
        >
          <option value="kei">軽自動車</option>
          <option value="normal">普通車</option>
          <option value="large">大型車</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>座席数 (0-10)</Label>
        <Select value={seats} onChange={(e) => setSeats(parseInt(e.target.value))}>
          {[...Array(11)].map((_, i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>
          <Checkbox
            type="checkbox"
            checked={isFloorSoaked}
            onChange={(e) => setIsFloorSoaked(e.target.checked)}
          />
          床に浸透している
        </Label>
      </FormGroup>
      <FormGroup>
        <Label>ドアの枚数 (0-5)</Label>
        <Select value={doors} onChange={(e) => setDoors(parseInt(e.target.value))}>
          {[...Array(6)].map((_, i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </Select>
      </FormGroup>
      <Result>
        計算結果: {totalPrice.toLocaleString()}円～
        <Ctext>実際の状況によって料金が前後する可能性があります。</Ctext>
      </Result>
      <ButtonContainer>
        <Button onClick={handleInquiry}>この内容でメールする。</Button>
      </ButtonContainer>
      {showModal && (
        <Modal>
          <ModalContent>
            <h2>追加情報の入力</h2>
            <Input 
              type="tel" 
              placeholder="電話番号" 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)} 
            />
            <Input 
              type="text" 
              placeholder="住所" 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
            />
            <ButtonContainer>
              <Button onClick={sendInquiry}>メールを作成</Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button onClick={() => setShowModal(false)}>キャンセル</Button>
            </ButtonContainer>
          </ModalContent>
        </Modal>
      )}
    </CalculatorContainer>
  );
};

export default CleaningCalculator;