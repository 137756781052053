import React from 'react';
import styled from 'styled-components';
import cleanCredit from "../images/お任せください.png";

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  bottom: -30px;
  max-width: 500px; // 画像の実際のサイズに合わせて調整
  aspect-ratio: 1 / 1;
  overflow: hidden; // はみ出した部分を隠す
`;

const Logo = styled.svg`
  position: absolute;
  top: 0;
  left: 10px;
  width: 38.33%; // コンテナの1/3のサイズ
  height: 38.33%;
  z-index: 1;
`;

const PersonImage = styled.img`
  position: absolute;
  top: 40px; // 上に10px移動
  left: 20%; // 左に10px移動
  width: 80%; // 幅を20px増やす
  height: 80%; // 高さを20px増やす
  object-fit: cover; // 画像が切れないように調整
`;

const LogoComponent: React.FC = () => {
  return (
    <LogoContainer>
      <Logo viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" fill="#FF5722" />
        <text x="50" y="40" fontSize="23.76" fontWeight="bold" fill="yellow" textAnchor="middle">
          出張
        </text>
        <text x="50" y="70" fontSize="23.76" fontWeight="bold" fill="yellow" textAnchor="middle">
          専門店
        </text>
      </Logo>
      <PersonImage src={cleanCredit} alt="お任せください" />
    </LogoContainer>
  );
};

export default LogoComponent;