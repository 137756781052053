import styled from "styled-components";

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 0 auto;
    flex-direction: column;
    align-items: center; // 中央寄せを追加
  }
`;

export default Container;
