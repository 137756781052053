import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import estimateImage from "./images/見積もり.jpg";
import startWorkImage from "./images/作業開始.jpg";
import postWorkCheckImage from "./images/作業後の確認.jpg";
import consultationImage from "./images/相談・ヒアリング.jpg";
import serviceAreaImage from "./images/対応エリア.png";
import scheduleAdjustmentImage from "./images/日程調整.jpg";
import cleanButy from "./images/美化清掃.jpg";
import cleanOption from "./images/オプション.jpg";
import cleanCredit from "./images/クレジット.png";

import Container from "./components/Container";
import FlexContainer from "./components/FlexContainer";
import Header from "./components/Header";
import Logo from "./components/Logo";
import CallNowButton from "./components/CallNowButton";
import BannerCall from "./components/BannerCall";
import FAQ from "./components/FAQ";
import SectionTitle from "./components/SectionTitle";
import MainVisual from "./components/MainVisual";
import BeforeAfter from "./components/BeforeAfter";
import CleaningCalculator from "./components/CleaningCalculator";


declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const ContactButtonBase = styled.a`
  display: inline-block;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s ease;
  margin: 10px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const PhoneButton = styled(ContactButtonBase)`
  background: #ff9933; // より鮮やかなオレンジ
  &:hover {
    background: #ff8000;
  }
`;

const EmailButton = styled(ContactButtonBase)`
  background: #3399ff; // より鮮やかな水色
  &:hover {
    background: #0080ff;
  }
`;

const LineButton = styled(ContactButtonBase)`
  background: #00cc66; // より鮮やかな黄緑
  &:hover {
    background: #00b359;
  }
`;

const servicesData = [
  { name: "軽自動車", price: "12,000円~" },
  { name: "普通車", price: "13,000円~" },
  { name: "ミニバン", price: "15,000円~" },
  { name: "商用バン", price: "18,000円~" },
  { name: "特殊車両", price: "20,000円~" },
  { name: "その他 ご相談下さい", price: "" },
];

const priceData = [
  { name: "シートクリーニング", price: "7,000円~" },
  { name: "天井クリーニング", price: "20,000円~" },
  { name: "フロアマット洗浄", price: "3,000円~" },
  { name: "カーペット洗浄", price: "10,000円~" },
  { name: "防カビコート", price: "8,000円~" },
  { name: "シート除菌", price: "8,000円~" },
  { name: "高濃度オゾン消臭", price: "10,000円~" },
  { name: "染み抜き", price: "7,000円~" },
  { name: "ヘッドライト黄ばみ取り", price: "10,000円~" },
];

const Section = styled.section`
  margin: 10px 0;
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const FlowItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FlowImage = styled.img`
  width: 280px;
  height: 200px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    max-width: 280px;
  }
`;

const FlowContent = styled.div`
  flex: 1;
`;

const PricingItem = styled.div`
  margin-bottom: 30px;
`;

const FlowContentWrapper = styled.div`
  width: 60%;
  margin-left: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
`;

const PricingFlexContainer = styled(FlexContainer)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PricingImage = styled.img`
  width: 280px;
  height: 200px;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTd = styled.td`
  padding: 8px;
  text-align: left;

  &:last-child {
    text-align: left;
  }
`;

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Footer = styled.footer`
  background: #35424a;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 60px;
`;

const ContactMethods = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  z-index: 1000;

  &.hidden {
    transform: translateY(100%);
  }

  @media (max-width: 768px) {
    bottom: 0;
    padding: 10px 0;
  }
`;

const ServiceAreaImage = styled.img`
  padding: 15px;
  max-width: 90%;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const emailSubject = encodeURIComponent("カークリーニングについての問い合わせ");
const emailBody = encodeURIComponent(
  `
【お問い合わせ内容】
・例 子供が吐いてしまった。おおよその金額を知りたい
・   後部座席の清掃料金を知りたい
・

【希望日時】
・
・
・

【車種】
・

【連絡先】
お名前：
電話番号 ※必須：
メールアドレス ※必須：

よろしくお願いいたします。
`.trim()
);

const mailtoLink = `mailto:spt.carcleeen@gmail.com?subject=${emailSubject}&body=${emailBody}`;

const flowData = [
  {
    image: consultationImage,
    title: "相談・ヒアリング",
    content: (
      <ul>
        <li>お困りごとをお聞かせください</li>
        <li>お客様のニーズに合ったご提案をいたします。</li>
      </ul>
    ),
  },
  {
    image: scheduleAdjustmentImage,
    title: "日程調整",
    content: (
      <ul>
        <li>最短即日対応いたします。</li>
        <li>お見積もりの日程またはご対応日を決定します。</li>
        <li>お客様のご都合をお聞かせください</li>
      </ul>
    ),
  },
  {
    image: estimateImage,
    title: "見積もり",
    content: <li>現地にて車内を確認し、お見積もり金額を決定します。</li>,
  },
  {
    image: startWorkImage,
    title: "作業開始",
    content: (
      <ul>
        <li>お客様がお見積もりに納得した場合にのみご対応いたします。</li>
        <li>作業時間は1～3h程かかります。</li>
        <li>※ご依頼内容によって長引く可能性がございます。</li>
      </ul>
    ),
  },
  {
    image: postWorkCheckImage,
    title: "作業完了後の確認",
    content: (
      <ul>
        <li>作業完了後お客様にて車内を確認していただきます。</li>
        <li>気になる点などなければ作業完了となります。</li>
      </ul>
    ),
  },
];

const CarCleaningLP: React.FC = () => {
  const [isContactMethodsHidden, setIsContactMethodsHidden] = useState(false);

  useEffect(() => {
    const checkFooterVisibility = () => {
      const footer = document.querySelector("footer");
      const contactMethods = document.getElementById("contactMethods");

      if (footer && contactMethods) {
        const footerRect = footer.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const contactMethodsHeight = contactMethods.offsetHeight;

        if (footerRect.top <= windowHeight - contactMethodsHeight) {
          setIsContactMethodsHidden(true);
        } else {
          setIsContactMethodsHidden(false);
        }
      }
    };

    window.addEventListener("scroll", checkFooterVisibility);
    window.addEventListener("resize", checkFooterVisibility);

    // 初回実行
    checkFooterVisibility();

    return () => {
      window.removeEventListener("scroll", checkFooterVisibility);
      window.removeEventListener("resize", checkFooterVisibility);
    };
  }, []);

  // const gtagCallButtonClick = () => {
  //   if (window.gtag) {
  //     window.gtag("config", "AW-16644451759/xzw7CKeL2McZEK_b2IA-", {
  //       phone_conversion_number: "070-9118-4433",
  //     });
  //   }
  //   // 電話リンクをフォロー
  //   window.location.href = "tel:070-9118-4433";
  // };

  const gtag_report_conversion = (
    url: string | undefined,
    sendTo: string,
    value: number
  ): boolean => {
    const callback = () => {
      if (typeof url !== "undefined") {
        window.location.href = url;
      }
    };
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: sendTo,
        value: value,
        currency: "JPY",
        event_callback: callback,
      });
    }
    return false;
  };

  return (
    <>
      <Helmet>
        <title>カークリマスター</title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16644451759"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16644451759');
          `}
        </script>
      </Helmet>
      <Header>
        <Container as={FlexContainer}>
          <Logo>カークリマスター</Logo>
          <CallNowButton
            href="tel:070-9118-4433"
            onClick={() =>
              gtag_report_conversion(
                "tel:070-9118-4433",
                "AW-16644451759/5-DFCJL4288ZEK_b2IA-",
                300.0
              )
            }
          >
            今すぐ電話
          </CallNowButton>
        </Container>
      </Header>
      <MainVisual />
      <BannerCall></BannerCall>
      <BeforeAfter />
      <ServiceAreaImage
        src={cleanCredit}
        alt="対応可能なお支払いは現金、クレジット"
      />
      <Section>
        <Container>
        <SectionTitle title="嘔吐清掃料金計算"></SectionTitle>
        <CleaningCalculator />
          <SectionTitle title="サービスの流れ"></SectionTitle>
          {flowData.map((flow, index) => (
            <FlowItem key={index}>
              <FlowImage src={flow.image} alt={`${flow.title}アイコン`} />
              <FlowContentWrapper>
                <FlowContent>
                  <h3>{flow.title}</h3>
                  {flow.content}
                </FlowContent>
              </FlowContentWrapper>
            </FlowItem>
          ))}
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle title="料金プラン"></SectionTitle>
          {[
            {
              header: "美化清掃",
              image: cleanButy,
              data: servicesData,
            },
            {
              header: "オプション",
              image: cleanOption,
              data: priceData,
            },
          ].map((pricing, index) => (
            <PricingItem key={index}>
              <h3>{pricing.header}</h3>
              <PricingFlexContainer>
                <PricingImage
                  src={pricing.image}
                  alt={`${pricing.header}アイコン`}
                />
                <StyledTable>
                  <tbody>
                    {pricing.data.map((item, idx) => (
                      <StyledTr key={idx}>
                        <StyledTd>{item.name}</StyledTd>
                        <StyledTd>{item.price}</StyledTd>
                      </StyledTr>
                    ))}
                  </tbody>
                </StyledTable>
              </PricingFlexContainer>
            </PricingItem>
          ))}
        </Container>
      </Section>
      <Section>
        <Container>
          <SectionTitle title="対応エリア"></SectionTitle>
          <ServiceAreaImage
            src={serviceAreaImage}
            alt="対応エリア：東京都、神奈川県、埼玉県、千葉県"
          />
        </Container>
      </Section>
      <Section>
        <Container>
          <FAQ />
        </Container>
      </Section>
      <ContactMethods
        id="contactMethods"
        className={isContactMethodsHidden ? "hidden" : ""}
      >
        <PhoneButton
          href="tel:070-9118-4433"
          onClick={() =>
            gtag_report_conversion(
              "tel:070-9118-4433",
              "AW-16644451759/5-DFCJL4288ZEK_b2IA-",
              300.0
            )
          }
        >
          {window.innerWidth <= 768 ? "電話" : "電話で問い合わせ"}
        </PhoneButton>
        <EmailButton
          href={mailtoLink}
          onClick={() =>
            gtag_report_conversion(
              "mailto:spt.carcleeen@gmail.com",
              "AW-16644451759/zXK5CKjj48cZEK_b2IA-",
              300
            )
          }
        >
          {window.innerWidth <= 768 ? "メール" : "メールで問い合わせ"}
        </EmailButton>
        <LineButton
          href="https://lin.ee/Iq5I8mn"
          onClick={() =>
            gtag_report_conversion(
              "https://lin.ee/Iq5I8mn",
              "AW-16644451759/slrQCLCe3McZEK_b2IA-",
              300
            )
          }
        >
          {window.innerWidth <= 768 ? "LINE" : "LINEで問い合わせ"}
        </LineButton>
      </ContactMethods>
      <Footer>
        <Container>
          <p>
            会社情報 | プライバシーポリシー |{" "}
            <a href={mailtoLink}>お問い合わせ</a>
          </p>
          <p>&copy; 2024 カークリマスター All Rights Reserved.</p>
        </Container>
      </Footer>
    </>
  );
};

export default CarCleaningLP;
