import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Svg = styled.svg`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const Rect = styled.rect`
  width: 100%;
  height: 200px;
  fill: #e8491d;
`;

const Text = styled.text`
  font-family: "Noto Sans JP", sans-serif;
  text-anchor: middle;
  fill: white;
`;

const Tspan = styled.tspan`
  font-family: "Noto Sans JP", sans-serif;
`;

const Line = styled.line`
  stroke: white;
  stroke-width: 2;
  stroke-dasharray: 10 5;
`;

const PhoneNumberLink = styled.a`
  text-decoration: none;
`;

const PhoneNumber = styled.text`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 42px;
  font-weight: 700;
  fill: yellow;
  text-anchor: middle;
  text-decoration: underline;
`;

const BannerCall: React.FC = () => {
  return (
    <Container>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 200">
        <defs>
          <style>
            @import
            url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
          </style>
        </defs>
        <Rect />
        <Text x="300" y="55" fontSize="24" fontWeight="700">
          <Tspan x="300" dy="0">
            費用はいくら？ いつ来れるの
          </Tspan>
          <Tspan x="300" dy="45" fontSize="28" fontWeight="700">
            お電話ですぐにお答えいたします！
          </Tspan>
        </Text>
        <Line x1="100" y1="130" x2="500" y2="130" />
        <PhoneNumberLink href="tel:070-9118-4433">
          <PhoneNumber x="300" y="175">
            070-9118-4433
          </PhoneNumber>
        </PhoneNumberLink>
      </Svg>
    </Container>
  );
};

export default BannerCall;
