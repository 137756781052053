import styled from "styled-components";

const ButtonBase = styled.a`
  display: inline-block;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s ease;
  margin: 0 10px;
`;

export default ButtonBase;
