import styled from "styled-components";
import ButtonBase from "./ButtonBase";

const CallNowButton = styled(ButtonBase)`
  background: #e8491d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  &:hover {
    background: #ff5722;
  }
`;

export default CallNowButton;
