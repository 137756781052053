import React from 'react';
import styled from 'styled-components';

const LogoFont = styled.div`
  font-family: 'Chokokutai', sans-serif; /* Chokokutaiフォントを指定 */
  font-size: 2em;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

interface SectionProps {
  children: React.ReactNode;
}

const Logo: React.FC<SectionProps> = ({ children }) => (
  <LogoFont>
    <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Chokokutai:wght@400;700&display=swap');
        </style>
      </defs>
    </svg>
    {children}
  </LogoFont>
);

export default Logo;
