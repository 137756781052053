import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* 垂直方向の中央寄せも必要なら追加 */
`;

const SectionTitle = styled.h2`
  text-align: center;
  display: inline-block;
  position: relative;
  font-size: 2em;
  font-weight: bold;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px; /* 下線の位置を調整 */
    width: 100%;
    height: 5px; /* 下線の太さを調整 */
    background-color: #e8491d;
  }
`;

interface SectionProps {
  title: string;
}

const Section: React.FC<SectionProps> = ({ title }) => (
  <SectionContainer>
    <SectionTitle>{title}</SectionTitle>
  </SectionContainer>
);

export default Section;
