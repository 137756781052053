import React, { useState } from "react";
import styled from "styled-components";
import SectionTitle from "./SectionTitle";
const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const FAQItem = styled.div`
  margin-bottom: 10px;
`;

const Question = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  color: #e8491d;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Answer = styled.div<{ $show: boolean }>`
  padding: 10px;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  display: ${(props) => (props.$show ? "block" : "none")};
  font-size: 1em;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
`;

const Icon = styled.span<{ $isOpen: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-right: 2px solid #e8491d;
  border-bottom: 2px solid #e8491d;
  transform: ${(props) => (props.$isOpen ? "rotate(45deg)" : "rotate(135deg)")};
  transition: transform 0.3s ease;
`;

const faqData = [
  {
    question: "Q1: 清掃にかかる時間はどのくらいですか？",
    answer:
      "車内清掃にかかる時間は、車のサイズや汚れの程度によりますが、通常は約2〜4時間程度です。事前にご予約いただくとスムーズに作業を進めることができます。",
  },
  {
    question: "Q2: どの地域でサービスを提供していますか？",
    answer:
      "サービスエリアは東京都、神奈川県、埼玉県、千葉県です。その他の地域についてはご相談ください。",
  },
  {
    question: "Q3: 清掃後に注意することはありますか？",
    answer:
      "清掃後は、シートやカーペットが完全に乾くまで時間がかかる場合があります。清掃後の注意点については、作業終了時にスタッフがご案内いたします。",
  },
  {
    question: "Q4: 営業時間は何時から何時までですか？",
    answer: "当社は24時間対応しておりますので、いつでもご連絡いただけます。",
  },
  {
    question: "Q5: 子供が吐いてしまってその部分だけ清掃してくれますか？",
    answer: "部分的でも作業させていただきます。詳細はお問い合わせください。",
  },
  {
    question: "Q6: 急いでいるのですが、どれくらいで作業してくれますか？",
    answer:
      "ご都合が合えば当日に作業させていただきます。なるべくお客様の要望に応えられるよう対応いたします。",
  },
];

const FAQ = () => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleFAQ = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <FAQContainer>
      <SectionTitle title="よくあるお問合せ"></SectionTitle>
      {faqData.map((item, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleFAQ(index)}>
            {item.question}
            <Icon $isOpen={openIndexes.includes(index)} />
          </Question>
          <Answer $show={openIndexes.includes(index)}>{item.answer}</Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;
