import React from 'react';
import styled from 'styled-components';
import Berfore from "../images/Berfore.jpg";
import After from "../images/After.jpg";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center; // 中央寄せを追加
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center; // 中央寄せを追加
  }
`;

const Section = styled.div`
  flex: 1;
  padding-top: 10px;
  padding-right: 30px;
  padding-left: 10px;
  text-align: center;
  /* max-width: 300px; // 最大幅を設定 */
  width: 80%; // 幅を100%に設定
  
  @media (max-width: 768px) {
    padding-right: 15%;
    padding-left: 10%;
    width: 80%; // モバイル時の幅を90%に設定
  }
`;

const Title = styled.h2`
  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
`;

const ImageFrame = styled.div<{ color: string }>`
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  padding-left: -10px;
  position: relative;
  overflow: hidden;
  border: 10px solid ${props => props.color};
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BeforeAfter: React.FC = () => {
  return (
    <>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');
      </style>
      <Container>
        <Section>
          <Title>Before</Title>
          <ImageFrame color="rgba(173, 216, 230, 0.7)"> {/* Light blue */}
            <Image src={Berfore} alt="Before" />
          </ImageFrame>
        </Section>
        <Section>
          <Title>After</Title>
          <ImageFrame color="rgba(255, 182, 193, 0.7)"> {/* Light red */}
            <Image src={After} alt="After" />
          </ImageFrame>
        </Section>
      </Container>
    </>
  );
};

export default BeforeAfter;